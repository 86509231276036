import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SimpleImageSlider from "react-simple-image-slider";
import Close from '@mui/icons-material/Close';
import { Dialog, IconButton } from "@mui/material";
const style = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minwidth: 400,
  bgcolor: "background.paper",
  position: "fixed",
 overflowY: "scroll", 
 overflowX: "scroll",
 maxHeight: "90%",
  boxShadow: 24,
  p: 4,
};
declare type URLObject = {
  url: string;
};
export const ModelComponent = (props: {
  open: boolean;
  handleClose: any;
  data: any;
}) => {
  let indices = 0;
  const { open, handleClose, data } = props;
  // Id: number;
  // Product: string;
  // Pack: string;
  // Mrp: number;
  // Description: string;
  // Power: string;
  // Type: string;
  // ImageFront: string;
  // ImageIngredients: string;
  // ImageCertifications: string;

  return (
    <div className="container">
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex flex-xl-row flex-column gap-2">
          <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
          </div>
          <div className="d-flex flex-xl-row flex-column gap-2">
            <div className=" d-flex justify-content-center">
              {data.Images.length==0?<></>:<><SimpleImageSlider
                width={400}
                height={550}
                images={data.Images}
                showBullets={true}
                showNavs={true}
              /></>}
            </div>
            <div className=" ">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {data?.Product}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Description:
              </Typography>
              {data?.MoreDetails !== "" ? (
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {data?.Description}
                  {data.MoreDetails?<>...
                  <a href={data?.MoreDetails}>
                    To Know More
                  </a></>:<></>}
                  
                </Typography>
              ) : (
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {data?.Description}
                </Typography>
              )}

              <div className="d-flex justify-content-between">
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Type:
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {data?.Type}
                </Typography>
              </div>
              <div className="d-flex justify-content-between">
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  MRP:
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  ₹{data?.Mrp}
                </Typography>
              </div>
              <div className="d-flex justify-content-center">
                {data.Link==null? <></>:<><Button href={data.Link}>Buy On Amazon</Button></>}
              </div>
            </div>
          </div>
        </Box>
      </Dialog>
    </div>
  );
};
export default ModelComponent;
