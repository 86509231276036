import { Container,Nav,Navbar}  from "react-bootstrap/";

import logo from '../data/Background_Logo.png';
export default function NavigationBar() {

  return (
    <Navbar expand="md" className="d-flex align-items-center justify-content-center">
      <Container fluid >
        <Navbar.Brand href="/" className="col-md-4 col-xl-5 justify-content-start ps-2"><img src={logo} width={"175px"}></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav"className="justify-content-start ps-3">
        <Nav >
            <Nav.Item>
              <Nav.Link href="/">Home</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link href="/products">Products</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/AboutUs">About Us</Nav.Link>
            </Nav.Item>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
}
