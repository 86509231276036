import React, { useState, useLayoutEffect } from "react";
import "../App.css";
import { Button, Typography } from "@mui/material";
import ModelComponent from "./ModalComponent"
import products from "../json/LifeOrganics2.json";
import noimagefound from "../data/noimagefound.jpg";
import { Height } from "@mui/icons-material";
type productTemplate = {
  Id: number;
  Product: string;
  Pack: string;
  Mrp: number;
  Description: string;
  Power: string;
  Type: string;
  Images: string[];
  Link: string;
  MoreDetails: string;
  Tags: string[];
  isvisible:Boolean;
};

const ProductsList = () => {
  let images=[] as string[];
  const [isayurveda,setIsAyurveda]= React.useState(true);
  const [isPharma,setIsPharma]= React.useState(false);
  const [isallproducts,setIsallproducts]= React.useState(false);

  useLayoutEffect(() => {}, [products]);
  let data = products as unknown as productTemplate[];
  const [open, setOpen] = React.useState(false);
  const [tempData, setTempData] = React.useState([] as any);
  let tempo = [] as any;
  const style = {
    
    width: 292,
    Height: 400
  };

  const setData = (element: any) => {
    element?.Images?.forEach((element:string) => {
      let k=require(`../data/${element}.png`) as string;
      console.log(k);
      images.push(k)});
    setTempData({
      ...element,Images:images
    });
    setOpen(true);
  };
  const handleClose = () => {
    setTempData({});
    setOpen(false);
  };
  return (
    <div className="container">
      <div className="justify-content-center align-content-end d-flex gap-2">
        <h1>
          OUR PRODUCTS
        </h1>
      </div>
      <div className="d-flex flex-wrap justify-content-around gap-2 mt-2 mb-2 ">
        <Button role="button" onClick={()=>{setIsAyurveda(true) 
                                            setIsPharma(false)
                                            setIsallproducts(false)}}>Online</Button>
        <Button role="button" onClick={()=>{setIsAyurveda(false) 
                                            setIsPharma(true)
                                            setIsallproducts(false) }}>Pharma</Button>
                                                    
        <Button role="button" onClick={()=>{setIsAyurveda(false) 
                                            setIsPharma(false)
                                            setIsallproducts(true) }}>All Products</Button>
      </div>
      <div className="d-flex flex-wrap  align-items-stretch">
        {data?.map((product: productTemplate, index: any) => (<>{product.Tags.includes("ayurveda") && isayurveda ?<>
          <div key={index} className="col-sm-11 col-md-4 col-xxl-3 p-3 ">
            <div className="card m">
              
              {product.Images.length==0?<>
                <img className="card-img-top p-2  justify-content-center " src={noimagefound}></img>
                </>:<div className="imgsize"               
                ><img
                className="card-img-top p-2 imgsize justify-content-center"
                src={require(`../data/${product.Images[0]}.png`)}
                alt={`${product.Product}`}
              /></div>}
              <div className="card-body">
                <h5 className="card-title">{product.Product}</h5>
                <p className="card-text">₹{product.Mrp}</p>                
                {/* <p className="card-text"> {product.Id}</p> */}
              </div>
              <div className="card-body d-flex justify-content-center">
              <div className="d-flex justify-content-center b-1">
                <Button variant="contained" onClick={() => setData(product)}>
                 Know More
                </Button>
                </div>
                <div className="d-flex  b-1">
                {product.Link==null? <></>:<><Button href={product.Link}>Buy On Amazon</Button></>}
                </div>
              </div>
            </div>
          </div>
          </>:<></> }</>))}
          {data?.map((product: productTemplate, index: any) => (<>{product.Tags.includes("pharma") && isPharma ?<>
          <div key={index} className="col-sm-11 col-md-4 col-xxl-3 p-3 ">
                <a role="button" className="product_list_items" onClick={() => setData(product)}>{product.Product}</a>            
          </div>
          </>:<></> }</>))}
          {data?.map((product: productTemplate, index: any) => (<>{isallproducts ?<>
          <div key={index} className="col-sm-11 col-md-4 col-xxl-3 p-3 ">
                <a role="button" className="product_list_items" onClick={() => setData(product)}>{product.Product}</a>            
          </div>
          </>:<></> }</>))}
      </div>

      <div>
        {open ? (
          <ModelComponent
            open={open}
            handleClose={handleClose}
            data={tempData}
          ></ModelComponent>
        ) : null}
      </div>
    </div>
  );
};

export default ProductsList;
