import React, { useLayoutEffect } from "react";
import logo from "../data/Background_Logo.png";
import "../App.css";
import home_page_1 from "../data/homepage_thumbnail.jpg";
import home_page_3 from "../data/home_screen_2.jpg";
const Home = () => {
  return (
    <div className="container-fluid">
      <div className="my-div pb-4">
        <img src={home_page_1}></img>
      </div>
      <div className="my-div">
        <img src={home_page_3}></img>
        <div className="centered d-flex flex-wrap flex-md-column">
          <h1 className="heading pt-1 ps-md-2">About Us</h1>
          <p className="d-block d-sm-none">
            Established in 2020, Life Organics is commited to becoming the
            foremost leader in the field of Healthcare with the blend of Allopathy,
            Ayurvedic and Nutraceutical products
          </p>
          <p className="d-none d-sm-block d-md-none">
            Established in 2020, Life Organics is commited to becoming the
            foremost 
          </p>
          <p className="d-none d-sm-block d-md-none">
            leader in the field of Healthcare with the blend of Allopathy,
            Ayurvedic and Nutraceutical products
          </p>
          <div className="p-2 d-flex flex-md-column">
            <p className="d-none d-md-block d-xl-block d-xxl-block">Established in 2020, Life Organics is commited to becoming the
            foremost leader</p>
            <p className="d-none d-md-block d-xl-block d-xxl-block"> in the field of Healthcare with the blend of Allopathy,
            Ayurvedic and Nutraceutical products</p>
            <p className="d-none d-md-block d-xl-block d-xxl-block"> We aim to provide a comprehensive range of natural solutions for holistic well-being, </p>
            <p className="d-none d-md-block d-xl-block d-xxl-block">  blending the ancient wisdom of Ayurveda with the latest scientific advancements.</p>
          </div>
          <a className=" text-color ps-md-2" href="/aboutUs">
            To Learn More About Us{">"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
